import { createSlice } from '@reduxjs/toolkit';

import { getLoanRenewalListWithFilter } from '../redux/thunk';

const initialState = {
    tabIndicatorPosition: {},
    loanRenewalTabList: [],
    loanRenewalDetails: {
        data: {
            data: {
                filteredData: {
                    count: 0,
                    rows: [],
                },
                distinctFilters: {
                    months: [],
                    products: [],
                    eligibility: [],
                },
            },
        },
    },
    isLoading: false,
    LoanRenewalDetailsName: 'customer',
    filterOptions: {
        products: [],
        eligibility: [],
        months: [],
    },
};

export const loanRenewalDetailSlice = createSlice({
    name: 'loanRenewalDetails',
    initialState,
    reducers: {
        setValueIndex(state, action) {
            state.tabIndicatorPosition = {
                ...state.tabIndicatorPosition,
                ...action.payload,
            };
        },
        clearValueIndex(state) {
            state.tabIndicatorPosition = {};
        },
        changeLoanRenewalDetailsName: (state, action) => {
            state.LoanRenewalDetailsName = action.payload;
        },
        setLoanRenewalTabList(state, action) {
            state.loanRenewalTabList = action.payload;
        },
        updateFilterOptions(state, action) {
            const { distinctFilters } = action.payload;

            // Update products in filter options
            if (distinctFilters?.products) {
                state.filterOptions.products = distinctFilters.products.map(
                    (product) => ({
                        filterID: product.code,
                        label: product.name,
                    })
                );
            } else if (distinctFilters?.months) {
                state.filterOptions.months = distinctFilters.months.map((month) => ({
                    filterID: month.value,
                    label: month.name,
                }));
            } else if (distinctFilters?.eligibility) {
                state.filterOptions.eligibility = distinctFilters.eligibility.map(
                    (eligibility) => ({
                        filterID: eligibility.value,
                        label: eligibility.name,
                    })
                );
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLoanRenewalListWithFilter.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getLoanRenewalListWithFilter.fulfilled, (state, action) => {
                state.isLoading = false;
                state.loanRenewalDetails.data.data.filteredData =
                    action?.payload?.data?.filteredData;
                state.loanRenewalDetails.data.data.distinctFilters =
                    action?.payload?.data?.distinctFilters;
                const distinctFilters = action?.payload?.data?.distinctFilters;
                if (distinctFilters) {
                    if (distinctFilters?.products) {
                        state.filterOptions.products = distinctFilters?.products.map(
                            (product) => ({
                                filterID: product.code,
                                label: product.name,
                            })
                        );
                    }
                } else if (distinctFilters?.months) {
                    state.filterOptions.months = distinctFilters?.months.map(
                        (month) => ({
                            filterID: month.value,
                            label: month.name,
                        })
                    );
                } else if (distinctFilters?.eligibility) {
                    state.filterOptions.eligibility =
                        distinctFilters?.eligibility.map((eligibility) => ({
                            filterID: eligibility.value,
                            label: eligibility.name,
                        }));
                }
            })
            .addCase(getLoanRenewalListWithFilter.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action?.payload;
            })
            .addCase('loanRenewalDetails/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

export const {
    changeLoanRenewalDetailsName,
    setValueIndex,
    clearValueIndex,
    setLoanRenewalTabList,
    updateFilterOptions,
} = loanRenewalDetailSlice.actions;

export default loanRenewalDetailSlice.reducer;
