import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tabIndicatorPosition: {},
    buttonState: {
        lonalRenewal: false,
        creditReject: false,
    },

    LoanRenewalName: 'customer',
};

export const loanRenewalSlice = createSlice({
    name: 'loanRenewal',
    initialState,
    reducers: {
        setValueIndex(state, action) {
            state.tabIndicatorPosition = action.payload;
        },
        clearValueIndex(state) {
            state.tabIndicatorPosition = {};
        },
        enableButton: (state, action) => {
            switch (action.payload) {
                case 'lonalRenewal':
                    state.buttonState.lonalRenewal = false;
                    break;
                case 'creditReject':
                    state.buttonState.creditReject = false;
                    break;
                default:
                    break;
            }
        },

        disableButton: (state, action) => {
            switch (action.payload) {
                case 'lonalRenewal':
                    state.buttonState.lonalRenewal = true;
                    break;

                case 'creditReject':
                    state.buttonState.creditReject = true;
                    break;
                default:
                    break;
            }
        },

        changeLoanRenewalName: (state, action) => {
            state.LoanRenewalName = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder.addCase('loanRenewal/CLEAR_STATE', () => {
            return initialState;
        });
    },
});

// Action creators are generated for each case reducer function

export const {
    setValueIndex,

    clearValueIndex,

    enableButton,
    disableButton,

    changeLoanRenewalName,
} = loanRenewalSlice.actions;

export default loanRenewalSlice.reducer;
