import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../../../service/apiService';

export const getExistingProductDetailsChecker = createAsyncThunk(
    'LR_DETAILS_CHECKER',
    async (prospect_id, { rejectWithValue }) => {
        try {
            const headers = {
                'device-type': 'WEB',
            };
            const response = await apiService.get(
                `/main/customer/${prospect_id}/loan-renewal/view`,
                { headers }
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
