import { createAsyncThunk } from '@reduxjs/toolkit';

// import { routeConstant } from '../../../routes/routeConstant';
import apiService from '../../../../../service/apiService';
import { disableButton, enableButton } from '../../../redux/slice';
// import { setLoanStatus } from '../../prospect-details/redux/slice';
// import { closeDialog } from './slice';
export const getLoanRenewalListWithFilter = createAsyncThunk(
    'LOAN_RENEWAL_DETAILS',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await apiService.post(
                '/main/customer/loan-renewal/lr-list',
                {
                    payload: {
                        pagination: {
                            skip: payload.skip || 0,
                            limit: payload.limit || 100,
                        },
                        searchFilter: payload.searchFilter || '',
                        filter: {
                            months:
                                payload.filterRequestPayloadData?.uploadedIn || [],
                            product:
                                payload.filterRequestPayloadData?.productCode || [],
                            eligibility:
                                payload.filterRequestPayloadData?.eligibility || '',
                            status: payload.filterRequestPayloadData?.status || '',
                        },
                        // data: payload.filterRequestPayloadData,
                    },
                },
                {
                    signal: payload.signal,
                    headers: {
                        'device-type': 'WEB',
                    },
                    cache: false,
                }
            );
            if (
                response?.data?.filteredData?.count === 0 ||
                response?.code === 'failure'
            ) {
                dispatch(disableButton('customer'));
            } else {
                dispatch(enableButton('customer'));
            }
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
