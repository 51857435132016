import { createSlice } from '@reduxjs/toolkit';

// import { modifyData } from '../../../../../utils/modifyRejectReaons';
import { getLoanRenewalCRListWithFilter } from './thunks';

const initialState = {
    filterListData: {},
    selectedCheckboxes: [],
    selectedRows: [],
    selectedGroupId: '',
    dialog: {
        open: false,
        content: null,
        type: null,
    },
    creditRejected: {
        data: {
            data: {
                filteredData: {
                    count: 0,
                    rows: [],
                },
            },
        },
    },
    isLoading: false,
    isAllSelected: false,
    apiRequest: {},
    groupList: [],
    totalItems: 0,
    groupMembersList: {},
    isRejectDrawerOpen: false,
    otherReasons: false,
    rejectReasons: [],
    rejectedValuesCount: 0,
    selectedReasonValues: [], // New array for selected values
    rejectComments: '',
    groupId: '',
};

export const creditRejectedSlice = createSlice({
    name: 'creditRejected',
    initialState,
    reducers: {
        toggleAllCheckbox: (state, action) => {
            const { selectAll, items } = action.payload;

            if (selectAll) {
                // Select all items
                state.selectedCheckboxes = items;
                state.isAllSelected = true;
            } else {
                // Deselect all items
                state.selectedCheckboxes = [];
                state.isAllSelected = false;
            }
            state.selectedRows = selectAll ? items : [];
            state.totalItems = items?.length; // Ensure totalItems reflects the count of selectable items
        },

        toggleIndividualCheckbox: (state, action) => {
            const { itemId, isChecked } = action.payload;
            if (isChecked) {
                state.selectedCheckboxes.push(itemId);
            } else {
                state.selectedCheckboxes = state.selectedCheckboxes.filter(
                    (id) => id !== itemId
                );
            }
        },
        setSelectedCheckboxes: (state, action) => {
            state.selectedCheckboxes = action.payload;
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload;
        },
        clearAllSelectedCheckboxes: (state) => {
            state.selectedCheckboxes = [];
        },
        resetFilterListData: (state) => {
            state.filterListData = {};
        },

        setSelectedGroupId: (state, action) => {
            state.selectedGroupId = action.payload;
        },
        openDialog: (state, action) => {
            state.dialog.open = true;
            state.dialog.content = action.payload.content;
            state.dialog.type = action.payload.type;
        },
        closeDialog: (state) => {
            state.dialog.open = false;
        },
        setApiRequest: (state, action) => {
            state.apiRequest = action.payload;
        },
        updateGroupList(state, action) {
            state.groupList = action.payload?.rows;
        },
        openCloseRejectDrawer(state, action) {
            state.isRejectDrawerOpen = action.payload;
            state.rejectComments = '';
            if (state.rejectReasons) {
                state.rejectReasons = state.rejectReasons.map((reason) => ({
                    ...reason,
                    type: false,
                }));
                state.selectedReasonValues = [];
                state.rejectedValuesCount = 0;
                state.otherReasons = false;
            }
        },
        updateRejectComments: (state, action) => {
            state.rejectComments = action.payload;
        },
        rejectSelectCheck(state, action) {
            state.rejectReasons[action.payload].type =
                !state.rejectReasons[action.payload].type;
            if (state.rejectReasons[action.payload].type) {
                state.selectedReasonValues.push(
                    state.rejectReasons[action.payload].id
                );
            } else {
                state.selectedReasonValues = state.selectedReasonValues.filter(
                    (value) => value !== state.rejectReasons[action.payload].id
                );
            }
            state.rejectedValuesCount = state?.selectedReasonValues?.length;
            if (
                state.rejectReasons[action.payload].value ===
                'Other reasons + comments'
            ) {
                state.otherReasons = state.rejectReasons[action.payload].type;
            }
        },
        clearGroupListData(state) {
            state.groupMembersList = {};
        },
        updateLoanAmount(state, action) {
            state.filterListData?.data?.filteredData?.rows.forEach(function (obj) {
                if (obj.cif_id === action.payload?.cif_id?.itemId) {
                    obj.loanAmount = action.payload.loanAmount;
                }
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLoanRenewalCRListWithFilter.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getLoanRenewalCRListWithFilter.fulfilled, (state, action) => {
                state.isLoading = false;
                state.creditRejected.data.data.filteredData =
                    action.payload.data.filteredData;
            })
            .addCase(getLoanRenewalCRListWithFilter.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase('creditRejected/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

// Action creators are generated for each case reducer function
export const {
    toggleAllCheckbox,
    setSelectedRows,
    setTotalItems,
    setSelectedCheckboxes,
    setApiRequest,
    setSelectedGroupId,
    clearAllSelectedCheckboxes,
    closeDialog,
    toggleIndividualCheckbox,
    updateGroupList,
    setGroupId,
    openCloseRejectDrawer,
    openDialog,
    rejectSelectCheck,
    updateRejectComments,
    clearGroupListData,
    updateLoanAmount,
} = creditRejectedSlice.actions;

export default creditRejectedSlice.reducer;
