import { createSelector } from '@reduxjs/toolkit';

const global = (state) => state.global;
const common = (state) => state.common;

export const selectPath = createSelector([global], (state) => state.path);
export const selectLoader = createSelector([common], (state) => state?.loader);
export const selectSnackBar = createSelector([common], (state) => state?.snackBar);

export const selectReportData = createSelector(
    [global],
    (state) => state?.reportPath
);
export const selectLoanRenewalData = createSelector(
    [global],
    (state) => state?.loanRenewalPath
);
export const selectLoanRenewalCheckerData = createSelector(
    [global],
    (state) => state?.loanRenewalCheckerPath
);
export const selectLifeCycleMasterLevel = createSelector(
    [global],
    (state) => state?.masterData
);

export const selectSelectedLoanRenewalTab = createSelector(
    [global],
    (state) => state?.selectedLoanRenewalTab
);

export const selectActiveCustomer = createSelector(
    [global],
    (state) => state?.activeCustomer
);

export const selectNotificationReportDownloadData = createSelector(
    [global],
    (state) => {
        const data = state?.notificationReportDownloadData || [];

        const sortedData = [...data].sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);

            return dateB - dateA;
        });

        return sortedData;
    }
);
