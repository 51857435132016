/**
 * Asynchronously loads the component for HomePage
 */

import loadable from '../../utils/loadable';

const LoginPage = loadable(() => import('../Login'), {
    fallback: <div className="flex-center">Login loading..</div>,
});

export default LoginPage;
