import { createSlice } from '@reduxjs/toolkit';

import { getExistingProductDetails } from './thunks';

// import { getExistingProductDetails } from './thunks';

const initialState = {
    value: 0,
    customerID: '',
    prospectDetails: {},
    overViewDetails: {},
    existingProductDetails: {},
    creditReport: {},
    finalConfirmation: {},
    disbursalDetails: {},
    loanStatus: '',
};

export const loanRenewalViewDetailsSlice = createSlice({
    name: 'loanRenewalViewDetails',
    initialState,
    reducers: {
        setCustomerID(state, action) {
            state.customerID = action.payload;
        },
        setLoanStatus(state, action) {
            state.loanStatus = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getExistingProductDetails.fulfilled, (state, action) => {
                state.existingProductDetails = action.payload?.data;
            })
            .addCase('loanRenewalViewDetails/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

// Action creators are generated for each case reducer function
export const { setCustomerID, setLoanStatus } = loanRenewalViewDetailsSlice.actions;

export default loanRenewalViewDetailsSlice.reducer;
