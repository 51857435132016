import { createSlice } from '@reduxjs/toolkit';

import { getLoanRenewalCheckerListWithFilter } from '../redux/thunks';

const initialState = {
    tabIndicatorPosition: {},
    selectedCheckboxes: [],
    loanRenewalCheckerTabList: [],
    loanRenewalCheckerDetails: {
        data: {
            data: {
                filteredData: {
                    count: 0,
                    rows: [],
                },
                distinctFilters: {
                    products: [],
                },
            },
        },
    },
    isLoading: false,
    LoanRenewalCheckerDetailsName: 'customer',
    filterOptions: {
        products: [],
        eligibility: [],
        months: [],
    },
};

export const loanRenewalCheckerDetailSlice = createSlice({
    name: 'loanRenewalCheckerDetails',
    initialState,
    reducers: {
        toggleAllCheckbox: (state, action) => {
            const { checkboxCount, filteredData } = action.payload;
            if (checkboxCount !== undefined) {
                const allSelected =
                    state.selectedCheckboxes.length === checkboxCount;

                if (allSelected) {
                    state.selectedCheckboxes = [];
                } else {
                    // Populate selectedCheckboxes with groupId of items with canReject as true
                    state.selectedCheckboxes = filteredData.rows.map(
                        (item) => item?.customerID
                    );
                }
            }
        },
        clearAllSelectedCheckboxes: (state) => {
            state.selectedCheckboxes = [];
        },
        toggleIndividualCheckbox: (state, action) => {
            const { itemId, isChecked } = action.payload;
            if (isChecked) {
                state.selectedCheckboxes?.push(itemId);
            } else {
                const indexToRemove = state.selectedCheckboxes?.indexOf(itemId);
                if (indexToRemove !== -1) {
                    state.selectedCheckboxes?.splice(indexToRemove, 1);
                }
            }
        },
        setValueIndex(state, action) {
            state.tabIndicatorPosition = {
                ...state.tabIndicatorPosition,
                ...action.payload,
            };
        },
        setFilteredListData: (state, action) => {
            state.filteredList = action.payload;
        },
        clearValueIndex(state) {
            state.tabIndicatorPosition = {};
        },
        setAlert: (state, action) => {
            state.type = action.payload.type;
            state.message = action.payload.message;
        },
        clearAlert: (state) => {
            state.type = null;
            state.message = null;
        },
        changeLoanRenewalCheckerDetailsName: (state, action) => {
            state.LoanRenewalCheckerDetailsName = action.payload;
        },
        setLoanRenewalCheckerTabList(state, action) {
            state.loanRenewalCheckerTabList = action.payload;
        },
        updateFilterOptions(state, action) {
            const { distinctFilters } = action.payload;

            // Update products in filter options
            if (distinctFilters?.products) {
                state.filterOptions.products = distinctFilters.products.map(
                    (product) => ({
                        filterID: product.code,
                        label: product.name,
                    })
                );
            } else if (distinctFilters?.months) {
                state.filterOptions.months = distinctFilters.months.map((month) => ({
                    filterID: month.value,
                    label: month.name,
                }));
            } else if (distinctFilters?.eligibility) {
                state.filterOptions.eligibility = distinctFilters.eligibility.map(
                    (eligibility) => ({
                        filterID: eligibility.value,
                        label: eligibility.name,
                    })
                );
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLoanRenewalCheckerListWithFilter.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(
                getLoanRenewalCheckerListWithFilter.fulfilled,
                (state, action) => {
                    state.isLoading = false;

                    // Ensure loanRenewalCheckerDetails and nested structures exist
                    state.loanRenewalCheckerDetails.data =
                        state.loanRenewalCheckerDetails.data || {};
                    state.loanRenewalCheckerDetails.data.data =
                        state.loanRenewalCheckerDetails.data.data || {};

                    // Update filtered data and distinct filters
                    state.loanRenewalCheckerDetails.data.data.filteredData = action
                        ?.payload?.data?.filteredData || {
                        count: 0,
                        rows: [],
                    };
                    state.loanRenewalCheckerDetails.data.data.distinctFilters =
                        action?.payload?.data?.distinctFilters || {
                            products: [],
                        };

                    // Update filter options with products if they exist
                    const distinctFilters = action?.payload?.data?.distinctFilters;
                    if (distinctFilters && distinctFilters.products) {
                        state.filterOptions.products = distinctFilters.products.map(
                            (product) => ({
                                filterID: product.code,
                                label: product.name,
                            })
                        );
                    } else if (distinctFilters?.months) {
                        state.filterOptions.months = distinctFilters?.months.map(
                            (month) => ({
                                filterID: month.value,
                                label: month.name,
                            })
                        );
                    } else if (distinctFilters?.eligibility) {
                        state.filterOptions.eligibility =
                            distinctFilters?.eligibility.map((eligibility) => ({
                                filterID: eligibility.value,
                                label: eligibility.name,
                            }));
                    }
                }
            )
            .addCase(
                getLoanRenewalCheckerListWithFilter.rejected,
                (state, action) => {
                    state.isLoading = false;
                    state.error = action.payload;
                }
            )
            // .addCase(approveRejectLoanRenewalChecker.pending, (state) => {
            //     state.isLoading = true;
            //     state.error = null;
            // })
            // .addCase(approveRejectLoanRenewalChecker.fulfilled, (state) => {
            //     state.isLoading = false;
            //     state.error = null;
            // })
            // .addCase(approveRejectLoanRenewalChecker.rejected, (state, action) => {
            //     state.isLoading = false;
            //     state.error = action.payload;
            // })
            .addCase('loanRenewalCheckerDetails/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

export const {
    toggleAllCheckbox,
    clearAllSelectedCheckboxes,
    toggleIndividualCheckbox,
    changeLoanRenewalCheckerDetailsName,
    setValueIndex,
    setAlert,
    clearAlert,
    setFilteredListData,
    clearValueIndex,
    setLoanRenewalCheckerTabList,
    updateFilterOptions,
} = loanRenewalCheckerDetailSlice.actions;

export default loanRenewalCheckerDetailSlice.reducer;
