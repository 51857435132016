export const routeConstant = {
    DASHBOARD: 'dashboard',
    CUSTOMER_DASHBOARD: 'customerDashboard',
    LOGIN: 'login',
    SET_PASSWORD: 'setPassword',
    SET_PASSWORD_SUCCESS: 'success',
    LOAN_APPROVAL: 'loanApproval',
    RETRIGGER: 'retrigger',
    REPORTS: 'reports',
    PROSPECT_DETAILS: 'prospect-details',
    AUDIT: 'audit',
    PROSPECT_REPORT: 'prospect-report',
    CREDIT_REPORT: 'credit-report',
    DISBURSAL_REPORT: 'disbursal-report',
    LOAN_RENEWAL: 'loan-renewal',
    LOAN_RENEWAL_CHECKER: 'loan-renewal-checker',
    LOAN_RENEWAL_DETAILS_CHECKER: 'loan-renewal-details-checker',
    CREDIT_REJECTED_DETAILS_CHECKER: 'credit-rejected-details-checker',
    LR_DETAILS_CHECKER: 'lr-details-checker',
    CR_DETAILS_CHECKER: 'cr-details-checker',
    LR_DETAILS: 'lr-details',
    CR_DETAILS: 'cr-details',
    PENDING: 'pending',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    LOAN_RENEWAL_DETAILS: 'loan-renewal-details',
    CREDIT_REJECTED_DETAILS: 'credit-rejected-details',
};
