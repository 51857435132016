import React from 'react';

import PropTypes from 'prop-types';

const NoDataCard = ({ customStyle }) => {
    const defaultStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        minHeight: '72px',
        width: '100%',
        ...customStyle,
    };

    return (
        <div
            className="flex items-center justify-center w-full"
            style={defaultStyles}
        >
            <span className="text-sm text-[#030037]">No data available</span>
        </div>
    );
};

NoDataCard.propTypes = {
    customStyle: PropTypes.object,
};

NoDataCard.defaultProps = {
    customStyle: {},
};

export default NoDataCard;
