import { Route } from 'react-router-dom';

import AppLayout from '../layouts/AppLayout';
import PrivateRoute from '../routes/privateRoute';

const generateRoutes = (routes) => {
    return routes.map((route, index) => {
        const {
            path,
            component: Component,
            private: isPrivate,
            showHeaderSidebar,
            roles,
        } = route;

        if (isPrivate) {
            return (
                <Route
                    key={index}
                    path={path}
                    element={
                        <PrivateRoute
                            element={
                                <AppLayout showHeaderSidebar={showHeaderSidebar}>
                                    <Component />
                                </AppLayout>
                            }
                            roles={roles}
                        />
                    }
                />
            );
        } else {
            return <Route key={index} path={path} element={<Component />} />;
        }
    });
};

export default generateRoutes;
