// reducers/index.js
import { configureStore } from '@reduxjs/toolkit';

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { persistStore } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

// import bulkUploadReducer from '../components/BulkUploadDialogue';
import auditReducer from '../features/audit/redux/slice';
import creditReoprtViewDetailsCheckerReducer from '../features/cr-checker-details/redux/slice';
import creditReoprtViewDetailsReducer from '../features/cr-details/redux/slice';
import customerDashboardReducer from '../features/customer-dashboard/redux/slice';
import customerSupportReducer from '../features/customer-support/redux/slice';
import dashboardReducer from '../features/dashboard/redux/slice';
import filterReducer from '../features/filter/redux/slice';
import loanApprovalReducer from '../features/loan-approval/redux/slice';
import loanRenewalReducer from '../features/loan-renewal/redux/slice';
import creditRejectedReducer from '../features/loan-renewal/sub-modules/credit-rejected-details/redux/slice';
import loanRenewalDetailsReducer from '../features/loan-renewal/sub-modules/loan-renewal-details/redux/slice';
import loanRenewalCheckerReducer from '../features/loan-renewal-checker/redux/slice';
import creditRejectedCheckerReducer from '../features/loan-renewal-checker/sub-modules/credit-rejected-details-checker/redux/slice';
import loanRenewalCheckerDetailsReducer from '../features/loan-renewal-checker/sub-modules/loan-renewal-details-checker/redux/slice';
import loginReducer from '../features/login/redux/slice';
import loanRenewalCheckerViewDetailsReducer from '../features/lr-checker-details/redux/slice';
import loanRenewalViewDetailsReducer from '../features/lr-details/redux/slice';
import prospectDetailsReducer from '../features/prospect-details/redux/slice';
import reportsReducer from '../features/reports/redux/slice';
import creditReport from '../features/reports/sub-modules/credit-report/redux/slice';
import disbursalReport from '../features/reports/sub-modules/disbursal-report/redux/slice';
import prospectReport from '../features/reports/sub-modules/prospect-report/redux/slice';
import commonReducer from '../redux/commonSlice';
import globalReducer from '../redux/slice';
import { loaderMiddleware, snackBarMiddleware } from '../service/apiMiddleware';
import clearAllReducersMiddleware from './clearAllReducersMiddleware';

const globalPersistConfig = {
    key: 'global',
    storage: storageSession,
    whitelist: ['path', 'reportPath', 'loanRenewalPath', 'loanRenewalCheckerPath'], // Only these will be persisted
};
// types.js

const rootReducer = combineReducers({
    login: loginReducer,
    audit: auditReducer,
    global: persistReducer(globalPersistConfig, globalReducer),
    filter: filterReducer,
    prospectDetails: prospectDetailsReducer,
    loanApproval: loanApprovalReducer,
    reports: reportsReducer,
    customerSupport: customerSupportReducer,
    customerDashboard: customerDashboardReducer,
    dashboard: dashboardReducer,
    common: commonReducer,
    loanRenewal: loanRenewalReducer,
    loanRenewalViewDetails: loanRenewalViewDetailsReducer,
    creditReoprtViewDetails: creditReoprtViewDetailsReducer,
    creditReoprtViewDetailsChecker: creditReoprtViewDetailsCheckerReducer,
    loanRenewalDetails: loanRenewalDetailsReducer,
    creditRejected: creditRejectedReducer,
    loanRenewalChecker: loanRenewalCheckerReducer,
    loanRenewalCheckerDetails: loanRenewalCheckerDetailsReducer,
    loanRenewalCheckerViewDetails: loanRenewalCheckerViewDetailsReducer,
    creditRejectedChecker: creditRejectedCheckerReducer,
    creditReport,
    disbursalReport,
    prospectReport,
});

const persistConfig = {
    key: 'root',
    version: 1,
    storage: storageSession,
    whitelist: [
        'login',
        'prospectDetails',
        'global',
        'creditReoprtViewDetails',
        'creditReoprtViewDetailsChecker',
    ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Define a root reducer that handles the DESTROY_SESSION action
// const rootReducerWithSessionHandling = (state, action) => {
//     if (action.type === DESTROY_SESSION) {
//         // Clear the entire Redux store when DESTROY_SESSION is dispatched
//         persistedReducer(undefined, action);
//     }
//     return persistedReducer(state, action);
// };

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(clearAllReducersMiddleware)
            .concat(loaderMiddleware)
            .concat(snackBarMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
});

export let persistor = persistStore(store);
