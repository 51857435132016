import { allRoleCodes } from '../appConfig';
import NoPageFound from '../components/NoPageFound';
import AuditPage from '../pages/lazy-loader/Audit';
import CustomerDashboardPage from '../pages/lazy-loader/CustomerDashboard';
import CustomerSupportPage from '../pages/lazy-loader/CustomerSupport';
import DashboardPage from '../pages/lazy-loader/Dashboard';
import LoanApprovalPage from '../pages/lazy-loader/LoanApproval';
import LoanRenewalPage from '../pages/lazy-loader/LoanRenewal';
import LoanRenewalCheckerPage from '../pages/lazy-loader/LoanRenewalChecker';
import LoginPage from '../pages/lazy-loader/Login';
import ReportsPage from '../pages/lazy-loader/Reports';
import { routeConstant } from './routeConstant';

const routes = [
    {
        path: '/',
        component: LoginPage,
        exact: true,
        roles: [],
    },
    {
        path: `/${routeConstant.LOGIN}/*`,
        component: LoginPage,
        exact: false,
        roles: [],
    },
    {
        path: `/${routeConstant.DASHBOARD}/*`,
        component: DashboardPage,
        exact: false,
        roles: allRoleCodes.dashboardRoles,
        private: true,
        showHeaderSidebar: true,
    },
    {
        path: `/${routeConstant.LOAN_APPROVAL}/*`,
        component: LoanApprovalPage,
        exact: false,
        roles: allRoleCodes.ProcessLoanRoles,
        private: true,
        showHeaderSidebar: true,
    },
    {
        path: `/${routeConstant.REPORTS}/*`,
        component: ReportsPage,
        exact: false,
        roles: allRoleCodes.reportRoles,
        private: true,
        showHeaderSidebar: true,
    },
    {
        path: `/${routeConstant.LOAN_RENEWAL}/*`,
        component: LoanRenewalPage,
        exact: false,
        roles: allRoleCodes.loanRenewal,
        private: true,
        showHeaderSidebar: true,
    },
    {
        path: `/${routeConstant.LOAN_RENEWAL_CHECKER}/*`,
        component: LoanRenewalCheckerPage,
        exact: false,
        roles: allRoleCodes.loanRenewalChecker,
        private: true,
        showHeaderSidebar: true,
    },
    {
        path: `/${routeConstant.AUDIT}/*`,
        component: AuditPage,
        exact: false,
        roles: allRoleCodes.auditRoles,
        private: true,
        showHeaderSidebar: true,
    },
    {
        path: `/${routeConstant.RETRIGGER}/*`,
        component: CustomerSupportPage,
        exact: false,
        roles: allRoleCodes.retriggerRoles,
        private: true,
        showHeaderSidebar: true,
    },
    {
        path: `/${routeConstant.CUSTOMER_DASHBOARD}/*`,
        component: CustomerDashboardPage,
        exact: false,
        roles: allRoleCodes.customerDashboardRoles,
        private: true,
        showHeaderSidebar: true,
    },

    {
        path: '*',
        component: NoPageFound,
        exact: true,
        roles: [],
    },
];

export default routes;
