import React from 'react';

import PropTypes from 'prop-types';

import Header from './Header';
import Sidebar from './Sidebar';

const AppLayout = ({ showHeaderSidebar, children }) => {
    return (
        <div className="app-container">
            {showHeaderSidebar && (
                <>
                    <div className="header">
                        <Header />
                    </div>
                    <div className="sidebar">
                        <Sidebar />
                    </div>
                </>
            )}
            <div className="main-content">{children}</div>
        </div>
    );
};

AppLayout.propTypes = {
    showHeaderSidebar: PropTypes.bool,
    children: PropTypes.node,
};

export default AppLayout;
