export const roleCodes = {
    SBM: 'RO10003',
    RH: 'RO10004',
    CPVE: 'RO10008',
    RM: 'RO10002',
    CSM: 'RO10010',
    NBU: 'RO10011',
    SBU: 'RO10006',
    FBBM: 'RO10013',
    FBBU: 'RO10014',
    TVE: 'RO10009',
    SAO: 'RO10016',
    SEBM: 'RO10017',
    AAO: 'RO10018',
    ABM: 'RO10019',
    FAO: 'RO10021',
    SA: 'RO10007',
    NA: 'RO10012',
    FBA: 'RO10015',
    FAA: 'RO10020',
    SEA: 'RO10022',
    AA: 'RO10023',
    NH: 'RO10005',
    AVBM: 'RO10025',
    BSTNGBM: 'RO10029',
    BLAZEBM: 'RO10032',
    GRMENBM: 'RO10035',
    JOTHIBM: 'RO10038',
    MAGILBM: 'RO10041',
    AHPA: 'RO10045',
    PAGBM: 'RO10049',
    AHOBM: 'RO10051',
};

export const appLevelRoles = {
    LR_MAKER: 'LR-MAKER',
    LR_CHECKER: 'LR-CHECKER',
};

export const allRoleCodes = {
    customerDashboardRoles: [roleCodes.FBA, roleCodes.AHPA],
    dashboardRoles: [roleCodes.FBA],
    ProcessLoanRoles: [
        roleCodes.SEBM,
        roleCodes.ABM,
        roleCodes.AVBM,
        roleCodes.BSTNGBM,
        roleCodes.BLAZEBM,
        roleCodes.GRMENBM,
        roleCodes.JOTHIBM,
        roleCodes.MAGILBM,
        roleCodes.PAGBM,
        roleCodes.AHOBM,
    ],
    reportRoles: [
        roleCodes.SEBM,
        roleCodes.ABM,
        roleCodes.AVBM,
        roleCodes.BSTNGBM,
        roleCodes.BLAZEBM,
        roleCodes.GRMENBM,
        roleCodes.JOTHIBM,
        roleCodes.MAGILBM,
        roleCodes.FBA,
        roleCodes.AHPA,
        roleCodes.PAGBM,
        roleCodes.AHOBM,
    ],
    auditRoles: [],
    retriggerRoles: [],
    loanRenewal: [roleCodes.FBA, appLevelRoles.LR_MAKER],
    loanRenewalChecker: [roleCodes.FBA, appLevelRoles.LR_CHECKER],
};

// RO10003|SBM |true     |SHECOM branch manager
// RO10004|RH |true     |SHECOM Regional Head
// RO10008|CPVE|true     |Contact Point Verificat
// RO10002|RM |true     |Relationship Manager
// RO10010|CSM |true     |NST Customer Success Ma
// RO10011|NBU |true     |NST Business User
// RO10006|SBU |true     |SHECOM Business User
// RO10013|FBBM|true     |Federal Bank Branch Man
// RO10014|FBBU|true     |Federal Bank Business U
// RO10009|TVE |true     |Tele-Verification Execu
// RO10016|SAO |true     |Seeds Agri Officer
// RO10017|SEBM|true     |Seeds Branch Manager
// RO10018|AAO |true     |Agrosperity Agri Office
// RO10019|ABM |true     |Agrosperity Branch Mana
// RO10021|FAO |true     |Federal Agri Officer
// RO10007|SA |true     |SheCom Admin
// RO10012|NA |true     |NST Admin
// RO10015|FBA |true     |Federal Bank Admin
// RO10020|FAA |true     |Federal Agri Admin
// RO10022|SEA |true     |Seeds Admin
// RO10023|AA |true     |Agrosperity Admin
// RO10005|NH |true     |SHECOM National Head
// RO10029|BSTNGBM |true     |Best NGO Branch Manager
// RO10032|BLAZEBM |true     |Blaze Trust Branch Manager
// RO10035|GRMENBM |true     |Grameen Branch Manager
// RO10038|JOTHIBM |true     |Jothi Linga Branch Manager
// RO10041|MAGILBM |true     |Magilchi Branch Manager
