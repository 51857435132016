/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tab, Tabs, Tooltip } from '@mui/material';

import {
    selectLoanRenewalCheckerData,
    selectLoanRenewalData,
    selectReportData,
} from '../../src/redux/selector.js';
import { allRoleCodes } from '../appConfig';
import AuditIcon from '../assets/svg/SidebarIcons/Audit';
import DashboardIcon from '../assets/svg/SidebarIcons/Dashboard';
import LoanRenewalIcon from '../assets/svg/SidebarIcons/LoanRenewalIcon.jsx';
import ProcessLoanIcon from '../assets/svg/SidebarIcons/ProcessLoan';
import ReportsIcon from '../assets/svg/SidebarIcons/Reports';
import RetriggerIcon from '../assets/svg/SidebarIcons/Retrigger';
import {
    filterChipsClearAll,
    resetPagination,
    updatePayloadWithPath,
} from '../features/filter/redux/slice';
import { clearGroupListData } from '../features/loan-approval/redux/slice.js';
import { userInfo } from '../features/login/redux/selector';
import { navigatePath, setSelectedCustomer } from '../redux/slice';
import { routeConstant } from '../routes/routeConstant';
import { theme } from '../theme.js';

const tabDiv2 = {
    zIndex: '1',
    display: 'flex',
};

const tabStyle = {
    minWidth: '0.5rem',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0px',
    '&.Mui-selected': {
        backgroundColor: 'transparent',
    },
};

const customIndicatorStyle = {
    backgroundColor: theme.palette.secondary.main,
    width: '70%',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '8px',
};

const hasLoanRenewalAccess = (appLevelRole = []) => {
    return appLevelRole?.some((role) => ['LR-MAKER', 'LR-CHECKER'].includes(role));
};

const Sidebar = React.memo(() => {
    const location = useLocation();
    const mainRoute = location.pathname;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector(userInfo);
    const reportPath = useSelector(selectReportData);
    const loanRenewalPath = useSelector(selectLoanRenewalData);
    const loanRenewalCheckerPath = useSelector(selectLoanRenewalCheckerData);

    const [valueIndex, setValueIndex] = useState({});

    const isAuthenticated = (roleCode, roles) => {
        if (roles?.includes('LR-MAKER') || roles?.includes('LR-CHECKER')) {
            return hasLoanRenewalAccess(userData?.appLevelRole);
        }
        return roleCode && roles?.includes(roleCode);
    };

    const setThemeOnSelect = (pathName) => {
        return mainRoute.includes(pathName)
            ? theme.palette.primary.main
            : theme.palette.common.grey;
    };

    const tabData = [
        {
            title: 'Dashboard',
            path: `/${routeConstant.DASHBOARD}`,
            icon: (
                <DashboardIcon
                    stroke={setThemeOnSelect(`/${routeConstant.DASHBOARD}`)}
                    fill={
                        mainRoute === '/dashboard'
                            ? 'none'
                            : theme.palette.common.white
                    }
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.dashboardRoles,
        },
        {
            title: 'Customer Dashboard',
            path: `/${routeConstant.CUSTOMER_DASHBOARD}`,
            icon: (
                <ProcessLoanIcon
                    fill={setThemeOnSelect(`/${routeConstant.CUSTOMER_DASHBOARD}`)}
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.customerDashboardRoles,
        },
        {
            title: 'Process Loan',
            path: `/${routeConstant.LOAN_APPROVAL}`,
            icon: (
                <ProcessLoanIcon
                    fill={setThemeOnSelect(`/${routeConstant.LOAN_APPROVAL}`)}
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.ProcessLoanRoles,
        },
        {
            title: 'Reports',
            path: reportPath,
            icon: (
                <ReportsIcon
                    fill={setThemeOnSelect(`/${routeConstant.REPORTS}`)}
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.reportRoles,
        },
        {
            title: 'Audit',
            path: `/${routeConstant.AUDIT}`,
            icon: (
                <AuditIcon
                    fill={setThemeOnSelect(`/${routeConstant.AUDIT}`)}
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.auditRoles,
        },
        {
            title: 'Customer Technical Support',
            path: `/${routeConstant.RETRIGGER}`,
            icon: (
                <RetriggerIcon
                    fill={setThemeOnSelect(`/${routeConstant.RETRIGGER}`)}
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.retriggerRoles,
        },
    ];

    // Add "Loan Renewal (Maker)" and "Loan Renewal (Checker)" icons based on roles
    if (userData?.appLevelRole?.includes('LR-MAKER')) {
        tabData.push({
            title: 'Loan Renewal (Maker)',
            path: loanRenewalPath,
            icon: (
                <LoanRenewalIcon
                    fill={setThemeOnSelect(`/${routeConstant.LOAN_RENEWAL}`)}
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.loanRenewal,
        });
    }

    if (userData?.appLevelRole?.includes('LR-CHECKER')) {
        tabData.push({
            title: 'Loan Renewal (Checker)',
            path: loanRenewalCheckerPath,
            icon: (
                <LoanRenewalIcon
                    fill={setThemeOnSelect(`/${routeConstant.LOAN_RENEWAL_CHECKER}`)}
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.loanRenewalChecker,
        });
    }

    useEffect(() => {
        console.log({ tabData });
        filteredTabs.forEach((tab, index) => {
            if (mainRoute?.includes(tab.path)) {
                setValueIndex(index);
            }
        });
    }, [userData, mainRoute]);

    // Filter tabs based on authentication
    const filteredTabs = tabData.filter((tab) =>
        isAuthenticated(
            tab.title.includes('Loan Renewal')
                ? userData?.appLevelRole[0]
                : userData?.roleCode,
            tab.role
        )
    );

    console.log({ valueIndex });
    console.log({ filteredTabs });
    return (
        <div className="sidebar-wrapper">
            <Tabs
                orientation="vertical"
                variant="scrollable"
                scrollButtons={false}
                value={valueIndex}
                aria-label="Vertical tabs"
                TabIndicatorProps={{ style: customIndicatorStyle }}
            >
                {filteredTabs?.map(
                    (tab, index) =>
                        isAuthenticated(
                            tab.title === 'Loan Renewal'
                                ? userData?.appLevelRole[0]
                                : userData?.roleCode,
                            tab.role
                        ) && (
                            <Tooltip
                                key={index}
                                title={tab.title}
                                placement="right"
                                arrow
                            >
                                <Tab
                                    icon={<div style={tabDiv2}>{tab.icon}</div>}
                                    sx={tabStyle}
                                    aria-label={tab.title}
                                    disableRipple
                                    onClick={() => {
                                        if (mainRoute !== tab.path) {
                                            console.log('Navigating to: ', tab.path);
                                            console.log(
                                                'UserData before navigation: ',
                                                userData
                                            );
                                            navigate(tab.path);
                                            dispatch(navigatePath(tab.path));
                                            dispatch(resetPagination());
                                            dispatch(setSelectedCustomer(null));
                                            dispatch(filterChipsClearAll());
                                            dispatch(clearGroupListData());
                                            dispatch(updatePayloadWithPath({}));
                                        }
                                    }}
                                />
                            </Tooltip>
                        )
                )}
            </Tabs>
        </div>
    );
});

Sidebar.displayName = 'Sidebar';
export default Sidebar;
