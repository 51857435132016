/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showSnackBar } from '../../../redux/commonSlice';
import apiService from '../../../service/apiService';
import { generatePDFFromHTML } from '../../../service/apiService';

const apiheaders = {
    'device-type': 'WEB',
};

export const getCreditReportDetails = createAsyncThunk(
    'CR_DETAILS',
    async (custId, { rejectWithValue }) => {
        console.log({ custId });
        try {
            const response = await apiService.get(
                `/main/customer/${custId}/loan-renewal/credit/view`,
                { headers: apiheaders, cache: false }
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

// export const downloadCreditReport = createAsyncThunk(
//     'creditReport/downloadReport',
//     async (custId, { dispatch }) => {
//         try {
//             const htmlReport = await apiService.get(
//                 `/credit/v1/customer/${custId}/creditReport`,
//                 {
//                     responseType: 'json',
//                     cache: false,
//                 }
//             );

//             const htmlContent = htmlReport?.data?.htmlReport;

//             if (!htmlContent) {
//                 throw new Error('No report content available');
//             }

//             console.log('HTML Content:', htmlContent);

//             const password = custId.toString();

//             const success = await generatePDFFromHTML(
//                 htmlContent,
//                 `Credit_Report_${custId}.pdf`,
//                 password
//             );

//             if (success) {
//                 dispatch(
//                     showSnackBar({
//                         snackBarOpen: true,
//                         allowClose: true,
//                         vertical: 'top',
//                         horizontal: 'right',
//                         duration: 3000,
//                         severity: 'success',
//                         snackBarMessage: `Report downloaded successfully. Use Customer ID (${custId}) as password to open the PDF.`,
//                     })
//                 );
//             } else {
//                 dispatch(
//                     showSnackBar({
//                         snackBarOpen: true,
//                         severity: 'error',
//                         snackBarMessage: 'Failed to download report',
//                     })
//                 );
//             }
//             return success;
//         } catch (error) {
//             console.error('Download Credit Report Error:', error);
//             dispatch(
//                 showSnackBar({
//                     snackBarOpen: true,
//                     allowClose: true,
//                     vertical: 'top',
//                     horizontal: 'right',
//                     duration: 2000,
//                     severity: 'error',
//                     snackBarMessage: 'Failed to download report',
//                 })
//             );
//             throw error;
//         }
//     }
// );

export const downloadCreditReport = createAsyncThunk(
    'creditReport/downloadReport',
    async (custId, { dispatch }) => {
        try {
            const response = await apiService.get(
                `/credit/v1/customer/${custId}/creditReport`,
                {
                    responseType: 'json',
                    cache: false,
                }
            );

            let htmlContent;
            if (response?.data?.htmlReport) {
                htmlContent = response.data.htmlReport;
            } else if (response?.htmlReport) {
                htmlContent = response.htmlReport;
            } else {
                throw new Error('Invalid response structure');
            }

            if (!htmlContent) {
                throw new Error('No report content available');
            }

            // Use customer ID as password
            const password = custId.toString();

            try {
                const success = await generatePDFFromHTML(
                    htmlContent,
                    `Credit_Report_${custId}.pdf`,
                    password
                );

                if (success) {
                    dispatch(
                        showSnackBar({
                            snackBarOpen: true,
                            allowClose: true,
                            vertical: 'top',
                            horizontal: 'right',
                            duration: 5000,
                            severity: 'success',
                            snackBarMessage: `Report downloaded successfully`, // Use Customer ID (${custId}) as password to open the PDF.
                        })
                    );
                    return true;
                } else {
                    throw new Error('PDF generation failed');
                }
            } catch (pdfError) {
                console.error('PDF Generation Error:', pdfError);
                throw pdfError;
            }
        } catch (error) {
            console.error('Download Credit Report Error:', error);
            dispatch(
                showSnackBar({
                    snackBarOpen: true,
                    allowClose: true,
                    vertical: 'top',
                    horizontal: 'right',
                    duration: 3000,
                    severity: 'error',
                    snackBarMessage: error.message || 'Failed to download report',
                })
            );
            throw error;
        }
    }
);
