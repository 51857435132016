import * as React from 'react';
const LoanRenewalIcon = (props) => (
    <svg
        width={30}
        height={33}
        viewBox="0 0 30 33"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M8.3449 1.47091C8.3449 2.79956 8.35032 4.08664 8.34309 5.3719C8.33224 7.45254 7.32717 8.44495 5.24472 8.44676C4.01369 8.44857 2.78266 8.44676 1.42147 8.44676C1.42147 8.81915 1.42147 9.13549 1.42147 9.45364C1.42147 14.7538 1.41966 20.0557 1.42328 25.3558C1.42509 27.6317 2.65431 28.8537 4.94103 28.8645C5.54299 28.8682 6.1576 28.7959 6.74329 28.8971C7.04698 28.9495 7.29644 29.3074 7.5694 29.528C7.30548 29.7666 7.04879 30.2004 6.77764 30.2095C5.66953 30.251 4.5343 30.2836 3.44789 30.0956C1.53355 29.7648 0.0187101 27.8577 0.0114794 25.8764C-0.00840511 20.0629 0.00244298 14.2512 0.00786603 8.43773C0.00967371 7.39108 0.439902 6.50712 1.16298 5.77501C2.68685 4.23125 4.22338 2.70195 5.76172 1.17265C6.57337 0.364614 7.58206 0.0193474 8.70463 0.0157321C12.3182 0.00488596 15.9336 -0.0168102 19.5471 0.0229588C22.2659 0.0536894 24.1856 2.00961 24.2001 4.73379C24.2236 9.13188 24.2073 13.5282 24.2073 17.9263C24.2073 18.0763 24.2634 18.2751 24.1892 18.3691C23.9723 18.6421 23.703 18.8735 23.4553 19.1211C23.233 18.859 22.8425 18.6096 22.8208 18.333C22.7431 17.3153 22.7901 16.2867 22.7901 15.2635C22.7901 11.8 22.7937 8.3365 22.7883 4.87298C22.7865 2.74714 21.4868 1.42211 19.3754 1.41669C16.0022 1.40765 12.6291 1.41307 9.25416 1.41669C8.99024 1.41669 8.72632 1.44741 8.3449 1.47091ZM7.00721 2.27353C5.40199 3.87513 3.82388 5.44963 2.25662 7.01327C3.30146 7.01327 4.4385 7.02412 5.57372 7.00966C6.44502 6.99881 6.95118 6.58304 6.99456 5.71716C7.0506 4.55663 7.00721 3.38887 7.00721 2.27353Z" />
        <path d="M22.9439 23.5481C20.7494 22.5141 21.4164 22.0749 22.7975 21.1692C24.4569 20.0828 26.064 18.9187 27.7035 17.7997C28.62 17.1742 29.0665 17.3514 29.2057 18.4595C29.4949 20.786 29.7498 23.1179 29.9866 25.4498C30.0861 26.435 29.6341 26.726 28.7339 26.3211C28.4664 26.2 28.1988 26.0771 27.9223 25.9505C27.2372 26.9213 26.6479 27.9028 25.9157 28.7651C21.4399 34.0435 13.3596 34.1592 8.73371 29.0128C8.51136 28.7651 8.53667 28.2969 8.44629 27.93C8.81686 27.8974 9.29409 27.6968 9.54174 27.8558C14.0194 30.7391 20.4258 29.2026 22.9439 23.5481ZM13.826 30.7807C15.9952 31.5761 18.0415 31.5652 20.1095 30.9614C23.1391 30.0775 25.142 28.0077 26.5701 25.307C26.975 24.5406 27.4288 24.0326 28.4628 24.5604C28.2675 22.7799 28.0868 21.1511 27.8915 19.376C26.4092 20.3973 25.0661 21.3247 23.6905 22.2719C24.6467 22.8106 24.8004 23.042 24.5094 23.6584C22.9023 27.0532 20.5198 29.5731 16.7056 30.4029C15.8108 30.5999 14.8853 30.6469 13.826 30.7807Z" />
        <path d="M12.1125 10.9937C13.8894 10.9937 15.6682 10.9973 17.4451 10.9918C17.9603 10.99 18.4719 11.0099 18.4502 11.7005C18.4285 12.3548 17.9296 12.4181 17.4126 12.4163C13.8569 12.4145 10.3012 12.4109 6.74546 12.4199C6.21039 12.4217 5.70423 12.3042 5.68616 11.7113C5.66446 11.0172 6.22485 10.9828 6.778 10.9882C8.55676 11.0045 10.3337 10.9937 12.1125 10.9937Z" />
        <path d="M12.0204 20.5585C10.2434 20.5585 8.46467 20.5494 6.68771 20.5639C6.13818 20.5675 5.62841 20.412 5.70976 19.8245C5.74772 19.5552 6.32075 19.1647 6.65517 19.1611C10.2705 19.1123 13.8859 19.1123 17.5013 19.1611C17.823 19.1647 18.3653 19.5588 18.4123 19.839C18.5226 20.4807 17.9677 20.5639 17.4434 20.5621C15.6357 20.5548 13.8281 20.5585 12.0204 20.5585Z" />
        <path d="M12.1317 23.2627C13.934 23.2627 15.738 23.2645 17.5403 23.2609C18.023 23.2609 18.4749 23.2934 18.4604 23.946C18.446 24.5353 18.0266 24.5877 17.5783 24.5877C13.9123 24.5877 10.2463 24.604 6.58032 24.5588C6.27121 24.5552 5.73614 24.1828 5.70541 23.9315C5.63852 23.3766 6.12479 23.2518 6.63275 23.2554C8.46574 23.2699 10.2987 23.2627 12.1317 23.2627Z" />
        <path d="M12.112 16.4383C10.3079 16.4383 8.50565 16.4311 6.70158 16.4438C6.17193 16.4474 5.62601 16.357 5.71459 15.7406C5.75074 15.4766 6.34185 15.1043 6.6835 15.1006C10.2898 15.0536 13.8962 15.0699 17.5025 15.0735C17.9743 15.0735 18.4461 15.126 18.4551 15.7586C18.4642 16.4004 18.005 16.4419 17.5206 16.4419C15.7183 16.4365 13.916 16.4383 12.112 16.4383Z" />
    </svg>
);
export default LoanRenewalIcon;
