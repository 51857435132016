import { createSlice } from '@reduxjs/toolkit';

import { loginUser, logoutApi, setPassword } from './thunks';

const initialState = {
    value: 0,
    data: null,
    userInfo: {
        lastLoggedIn: '',
        partnerId: '',
        productId: '',
        role: '',
        roleCode: '',
        userName: '',
        profileImage: '',
        appLevelRole: [],
    },
    setPass: null,
    userId: null,
    logoutResponse: null,
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        cleardata(state) {
            state.data = null;
            state.setPass = null;
            state.userInfo = null;
            state.userId = null;
        },
        storeValues(state, action) {
            state.userId = action?.payload?.employeeId;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.fulfilled, (state, action) => {
                const LoginResponse = action.payload;
                const modifiedPayload = JSON.parse(JSON.stringify(LoginResponse));

                delete modifiedPayload?.data?.accessToken;
                delete modifiedPayload?.data?.refreshToken;

                state.data = modifiedPayload;
                state.logoutResponse = null;
                const info = action?.payload?.data;

                if (info) {
                    state.userInfo = {
                        lastLoggedIn: info.lastLoggedIn,
                        partnerId: info.partnerId,
                        productId: info.productId,
                        role: info.role,
                        roleCode: info.roleCode,
                        userName: info.userName,
                        profileImage: info.profileImage,
                        // appLevelRole: info?.appLevelRole,
                        appLevelRole: info?.appLevelRole,
                    };
                }
            })
            .addCase(setPassword.fulfilled, (state, action) => {
                state.setPass = action.payload;
            })
            .addCase(logoutApi.fulfilled, (state, action) => {
                state.logoutResponse = action.payload;
            })
            .addCase('login/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

// Action creators are generated for each case reducer function
export const { cleardata, storeValues } = loginSlice.actions;

export default loginSlice.reducer;
