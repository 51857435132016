import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tabIndicatorPosition: {},
    buttonState: {
        lonalRenewalChecker: false,
        creditRejectChecker: false,
    },

    LoanRenewalCheckerName: 'customer',
};

export const loanRenewalCheckerSlice = createSlice({
    name: 'loanRenewalChecker',
    initialState,
    reducers: {
        setValueIndex(state, action) {
            state.tabIndicatorPosition = action.payload;
        },
        clearValueIndex(state) {
            state.tabIndicatorPosition = {};
        },
        enableButton: (state, action) => {
            switch (action.payload) {
                case 'lonalRenewal':
                    state.buttonState.lonalRenewalChecker = false;
                    break;
                case 'creditReject':
                    state.buttonState.creditRejectChecker = false;
                    break;
                default:
                    break;
            }
        },

        disableButton: (state, action) => {
            switch (action.payload) {
                case 'lonalRenewal':
                    state.buttonState.lonalRenewalChecker = true;
                    break;

                case 'creditReject':
                    state.buttonState.creditRejectChecker = true;
                    break;
                default:
                    break;
            }
        },

        changeLoanRenewalCheckerName: (state, action) => {
            state.LoanRenewalCheckerName = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder.addCase('loanRenewalChecker/CLEAR_STATE', () => {
            return initialState;
        });
    },
});

// Action creators are generated for each case reducer function

export const {
    setValueIndex,

    clearValueIndex,

    enableButton,
    disableButton,

    changeLoanRenewalCheckerName,
} = loanRenewalCheckerSlice.actions;

export default loanRenewalCheckerSlice.reducer;
