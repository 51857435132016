import { createSlice } from '@reduxjs/toolkit';

import { getLoanRenewalCheckerCRListWithFilter } from './thunks';

// import { modifyData } from '../../../../../utils/modifyRejectReaons';
// import { getLoanRenewalCRListWithFilter } from './thunks';

const initialState = {
    filterListData: {},
    selectedCheckboxes: [],
    selectedGroupId: '',
    dialog: {
        open: false,
        content: null,
        type: null,
    },
    creditRejectedChecker: {
        data: {
            data: {
                filteredData: {
                    count: 0,
                    rows: [],
                },
            },
        },
    },
    isLoading: false,
    apiRequest: {},
    groupList: [],
    groupMembersList: {},
    isRejectDrawerOpen: false,
    otherReasons: false,
    rejectReasons: [],
    rejectedValuesCount: 0,
    selectedReasonValues: [], // New array for selected values
    rejectComments: '',
    groupId: '',
};

export const creditRejectedCheckerSlice = createSlice({
    name: 'creditRejectedChecker',
    initialState,
    reducers: {
        toggleAllCheckbox: (state, action) => {
            const { filteredData } = action.payload;
            const allSelected =
                state.selectedCheckboxes.length === filteredData.count;
            state.selectedCheckboxes = allSelected
                ? []
                : filteredData?.rows?.map((item) => item.cif_id);
        },
        toggleIndividualCheckbox: (state, action) => {
            const { itemId, isChecked } = action.payload;
            if (isChecked) {
                state.selectedCheckboxes.push(itemId);
            } else {
                state.selectedCheckboxes = state.selectedCheckboxes.filter(
                    (id) => id !== itemId
                );
            }
        },
        setSelectedCheckboxes: (state, action) => {
            state.selectedCheckboxes = action.payload;
        },
        clearAllSelectedCheckboxes: (state) => {
            state.selectedCheckboxes = [];
        },
        resetFilterListData: (state) => {
            state.filterListData = {};
        },

        setSelectedGroupId: (state, action) => {
            state.selectedGroupId = action.payload;
        },
        openDialog: (state, action) => {
            state.dialog.open = true;
            state.dialog.content = action.payload.content;
            state.dialog.type = action.payload.type;
        },
        closeDialog: (state) => {
            state.dialog.open = false;
        },
        setApiRequest: (state, action) => {
            state.apiRequest = action.payload;
        },
        updateGroupList(state, action) {
            state.groupList = action.payload?.rows;
        },
        openCloseRejectDrawer(state, action) {
            state.isRejectDrawerOpen = action.payload;
            state.rejectComments = '';
            if (state.rejectReasons) {
                state.rejectReasons = state.rejectReasons.map((reason) => ({
                    ...reason,
                    type: false,
                }));
                state.selectedReasonValues = [];
                state.rejectedValuesCount = 0;
                state.otherReasons = false;
            }
        },
        updateRejectComments: (state, action) => {
            state.rejectComments = action.payload;
        },
        rejectSelectCheck(state, action) {
            state.rejectReasons[action?.payload].type =
                !state.rejectReasons[action?.payload].type;
            if (state.rejectReasons[action?.payload].type) {
                state?.selectedReasonValues?.push(
                    state.rejectReasons[action?.payload].id
                );
            } else {
                state.selectedReasonValues = state.selectedReasonValues.filter(
                    (value) => value !== state.rejectReasons[action?.payload].id
                );
            }
            state.rejectedValuesCount = state.selectedReasonValues.length;
            if (
                state.rejectReasons[action?.payload].value ===
                'Other reasons + comments'
            ) {
                state.otherReasons = state.rejectReasons[action?.payload].type;
            }
        },
        clearGroupListData(state) {
            state.groupMembersList = {};
        },
        updateLoanAmount(state, action) {
            state.filterListData?.data?.filteredData?.rows?.forEach(function (obj) {
                if (obj?.cif_id === action?.payload?.cif_id?.itemId) {
                    obj.loanAmount = action?.payload?.loanAmount;
                }
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLoanRenewalCheckerCRListWithFilter.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(
                getLoanRenewalCheckerCRListWithFilter.fulfilled,
                (state, action) => {
                    state.isLoading = false;
                    if (!state.creditRejectedChecker) {
                        state.creditRejectedChecker = { data: { data: {} } };
                    }
                    state.creditRejectedChecker.data.data.filteredData = action
                        ?.payload?.data?.filteredData || {
                        count: 0,
                        rows: [],
                    };
                }
            )
            .addCase(
                getLoanRenewalCheckerCRListWithFilter.rejected,
                (state, action) => {
                    state.isLoading = false;
                    state.error = action?.payload;
                }
            )
            .addCase('creditRejectedChecker/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

// Action creators are generated for each case reducer function
export const {
    toggleAllCheckbox,
    setSelectedCheckboxes,
    setApiRequest,
    setSelectedGroupId,
    clearAllSelectedCheckboxes,
    closeDialog,
    toggleIndividualCheckbox,
    updateGroupList,
    setGroupId,
    openCloseRejectDrawer,
    openDialog,
    rejectSelectCheck,
    updateRejectComments,
    clearGroupListData,
    updateLoanAmount,
} = creditRejectedCheckerSlice.actions;

export default creditRejectedCheckerSlice.reducer;
