/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';

import {
    customerDashboardFilter,
    customerSupportFilter,
    // disbursalReportFilter,
    filterValuesToPayload,
    groupReportFilter,
    loanApprovalFilter,
    loanRenewalCheckerFilter,
    loanRenewalCheckerRejectFilter,
    loanRenewalFilter,
    loanRenewalRejectFilter,
} from './utils';

const initialState = {
    value: 0,
    data: null,
    filteredValues: [],
    loanRenwalFilterConfig: [
        {
            filterCategory: 'uploadedIn',
            filterTitle: 'Uploaded In',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'productCode',
            filterTitle: 'Product',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'eligibility',
            filterTitle: 'Eligiblity',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        // {
        //     filterCategory: 'uneligible',
        //     filterTitle: 'Uneligible',
        //     filterType: 'CHECKBOX',
        //     showLabelIcon: true,
        // },
    ],
    loanRenwalRejectFilterConfig: [
        {
            filterCategory: 'uploadedIn',
            filterTitle: 'Uploaded In',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
    ],
    loanRenwalCheckerFilterConfig: [
        {
            filterCategory: 'uploadedIn',
            filterTitle: 'Uploaded In',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'productCode',
            filterTitle: 'Product',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'eligibility',
            filterTitle: 'Eligiblity',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        // {
        //     filterCategory: 'uneligible',
        //     filterTitle: 'Uneligible',
        //     filterType: 'CHECKBOX',
        //     showLabelIcon: true,
        // },
    ],
    loanRenwalCheckerRejectFilterConfig: [
        {
            filterCategory: 'uploadedIn',
            filterTitle: 'Uploaded In',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
    ],
    groupReportFilterConfig: [
        {
            filterCategory: 'pincode',
            filterTitle: 'Pincode',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'productCode',
            filterTitle: 'Product',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'stakeholderCode',
            filterTitle: 'Partner',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        // {
        //     filterCategory: 'village',
        //     filterTitle: 'Village',
        //     filterType: 'CHECKBOX',
        //     showLabelIcon: true,
        // },
        {
            filterCategory: 'stage',
            filterTitle: 'Stage',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'date',
            filterType: 'DATE_RANGE_PICKER',
            showLabelIcon: false,
            showCustomRangePicker: true,
            defaultOptions: [
                {
                    filterCategory: 'date_picker',
                    label: 'Last 7 days',
                    filterID: '7days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 15 days',
                    filterID: '15days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 30 days',
                    filterID: '30days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 3 months',
                    filterID: '3months',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 6 months',
                    filterID: '6months',
                },
            ],
        },
    ],
    customerDashboardFilterConfig: [
        {
            filterCategory: 'stateCode',
            filterTitle: 'States',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'districtCode',
            filterTitle: 'Districts',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'productCode',
            filterTitle: 'Product',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'bc',
            filterTitle: 'BC',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'stage',
            filterTitle: 'Stage',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'status',
            filterTitle: 'Status',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'date',
            filterType: 'DATE_RANGE_PICKER',
            showLabelIcon: false,
            showCustomRangePicker: true,
            defaultOptions: [
                {
                    filterCategory: 'date_picker',
                    label: 'Last 7 days',
                    filterID: '7days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 15 days',
                    filterID: '15days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 30 days',
                    filterID: '30days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 3 months',
                    filterID: '3months',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 6 months',
                    filterID: '6months',
                },
            ],
        },
    ],
    disbursalReportFilterConfig: [
        {
            filterCategory: 'pincode',
            filterTitle: 'Pincode',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'productCode',
            filterTitle: 'Product',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'stakeholderCode',
            filterTitle: 'Partner',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        // {
        //     filterCategory: 'village',
        //     filterTitle: 'Village',
        //     filterType: 'CHECKBOX',
        //     showLabelIcon: true,
        // },
        {
            filterCategory: 'stage',
            filterTitle: 'Stage',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'date',
            filterType: 'DATE_RANGE_PICKER',
            showLabelIcon: false,
            showCustomRangePicker: true,
            defaultOptions: [
                {
                    filterCategory: 'date_picker',
                    label: 'Last 7 days',
                    filterID: '7days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 15 days',
                    filterID: '15days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 30 days',
                    filterID: '30days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 3 months',
                    filterID: '3months',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 6 months',
                    filterID: '6months',
                },
            ],
        },
    ],
    auditFilterConfig: [
        {
            filterCategory: 'productCode',
            filterTitle: 'Product',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'stakeholderCode',
            filterTitle: 'Partner',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'pincode',
            filterTitle: 'Pincode',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'village',
            filterTitle: 'Village',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'stage',
            filterTitle: 'Stage',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'date',
            filterType: 'DATE_RANGE_PICKER',
            showLabelIcon: false,
            showCustomRangePicker: true,
            defaultOptions: [
                {
                    filterCategory: 'date_picker',
                    label: 'Last 7 days',
                    filterID: '7days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 15 days',
                    filterID: '15days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 30 days',
                    filterID: '30days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 3 months',
                    filterID: '3months',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 6 months',
                    filterID: '6months',
                },
            ],
        },
    ],
    loanApprovalFilterConfig: [
        {
            filterCategory: 'pincode',
            filterTitle: 'Pincode',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        // {
        //     filterCategory: 'village',
        //     filterTitle: 'Village',
        //     filterType: 'CHECKBOX',
        //     showLabelIcon: true,
        // },
        {
            filterCategory: 'productCode',
            filterTitle: 'Product',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'stakeholderCode',
            filterTitle: 'Partner',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        // {
        //     filterCategory: 'stage',
        //     filterTitle: 'Stage',
        //     filterType: 'CHECKBOX',
        //     showLabelIcon: true,
        // },
        {
            filterCategory: 'date',
            filterType: 'DATE_RANGE_PICKER',
            showLabelIcon: false,
            showCustomRangePicker: true,
            defaultOptions: [
                {
                    filterCategory: 'date_picker',
                    label: 'Last 7 days',
                    filterID: '7days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 15 days',
                    filterID: '15days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 30 days',
                    filterID: '30days',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 3 months',
                    filterID: '3months',
                },
                {
                    filterCategory: 'date_picker',
                    label: 'Last 6 months',
                    filterID: '6months',
                },
            ],
        },
    ],
    customerSupportFilterConfig: [
        {
            filterCategory: 'productCode',
            filterTitle: 'Product',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
        {
            filterCategory: 'apiName',
            filterTitle: 'API',
            filterType: 'CHECKBOX',
            showLabelIcon: true,
        },
    ],
    auditOptionsData: null,
    loanApprovalOptionsData: null,
    loanRenewalOptionsData: null,
    loanRenewalCheckerOptionsData: null,
    loanRenewalRejectOptionsData: null,
    loanRenewalCheckerRejectOptionsData: null,
    groupReportOptionsData: null,
    disbursalReportOptionsData: null,
    customerSupportOptionsData: null,
    customerDashboardOptionsData: [],
    isAllCleared: false,
    deletedChip: {},
    currfilterCategory: '',
    searchText: '',
    skip: 0,
    limit: 10,
    currentPage: 1,
    totalResults: 0,
    rowsPerPage: [5, 10, 25],
    filterRequestPayloadData: {},
    distinctFilters: {},
    defaultDateRequired: true,
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        auditFilterOptionsUpdate(state, action) {
            state.audit = action.payload;
        },
        updateTotalResults(state, action) {
            state.totalResults = action.payload;
        },
        updateFilterPayloadData(state, action) {
            const payload = action.payload?.path;
            const filterPayloadName = payload?.replace(/^\/+/g, '');
            const filterPayload = filterValuesToPayload(
                action.payload?.filteredValues,
                filterPayloadName
            );
            state.filterRequestPayloadData = filterPayload;
        },
        updatePayloadWithPath(state, action) {
            state.filterRequestPayloadData = action.payload;
        },
        updateLoanApprovalOptionsData(state, action) {
            state.loanApprovalOptionsData = loanApprovalFilter(action.payload);
        },
        updateLoanRenewalOptionsData(state, action) {
            console.log(action.payload);
            state.loanRenewalOptionsData = loanRenewalFilter(action.payload);
        },
        updateLoanRenewalRejectOptionsData(state, action) {
            state.loanRenewalRejectOptionsData = loanRenewalRejectFilter(
                action.payload
            );
        },
        updateLoanRenewalCheckerOptionsData(state, action) {
            state.loanRenewalCheckerOptionsData = loanRenewalCheckerFilter(
                action.payload
            );
        },
        updateLoanRenewalCheckerRejectOptionsData(state, action) {
            state.loanRenewalCheckerRejectOptionsData =
                loanRenewalCheckerRejectFilter(action.payload);
        },
        updateCustomerDashboardOptionsData(state, action) {
            console.log(action.payload);
            state.customerDashboardOptionsData = customerDashboardFilter(
                action.payload
            );
        },
        updateGroupReportOptionsData(state, action) {
            state.groupReportOptionsData = groupReportFilter(action.payload);
        },
        // updateDisbursalReportOptionsData(state, action) {
        //     state.disbursalReportOptionsData = disbursalReportFilter(action.payload);
        // },
        updateCustomerSupportOptionsData(state, action) {
            state.customerSupportOptionsData = customerSupportFilter(action.payload);
        },
        filterChipsAdd(state, action) {
            console.log(state.filteredValues, 'state.filteredValues');
            state.isAllCleared = false;
            state.currfilterCategory = action.payload?.filterCategory;

            const isDuplicate = state.filteredValues.some(
                (item) => item.filterID === action.payload.filterID
            );
            if (!isDuplicate) {
                state.filteredValues.push(action.payload);
            }
            state.skip = 0;
            state.currentPage = 1;
        },
        filterChipsDelete(state, action) {
            state.currfilterCategory = action.payload?.filterCategory;
            state.deletedChip = action.payload;
            state.filteredValues = state.filteredValues?.filter((item) => {
                return action.payload?.filterID !== item.filterID;
            });
            state.skip = 0;
            state.currentPage = 1;
        },
        filterChipsClearAll(state) {
            state.currentPage = 1;
            state.filteredValues = [];
            state.isAllCleared = true;
            state.currfilterCategory = '';
            state.skip = 0;
        },
        pageChange(state, action) {
            state.currentPage = action.payload;
            state.skip = (action.payload - 1) * state.limit;
        },
        rowsPerPageChange(state, action) {
            state.skip = 0;
            state.currentPage = 1;
            state.limit = action.payload;
        },
        setDistinctFilters(state, action) {
            state.distinctFilters = action.payload;
        },
        setSearchText(state, action) {
            state.searchText = action.payload;
        },
        resetPagination(state) {
            state.limit = 10;
            state.skip = 0;
            state.currentPage = 1;
        },
        setDefaultDate(state, action) {
            state.defaultDateRequired = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('filter/CLEAR_STATE', () => {
            return initialState;
        });
    },
});
// Action creators are generated for each case reducer function
export const {
    auditFilterOptionsUpdate,
    updateTotalResults,
    updateFilterPayloadData,
    updateGroupReportPayloadData,
    updateLoanRenewalOptionsData,
    updateLoanRenewalRejectOptionsData,
    updateLoanRenewalCheckerOptionsData,
    updateLoanRenewalCheckerRejectOptionsData,
    updateLoanApprovalOptionsData,
    updateGroupReportOptionsData,
    updateCustomerDashboardOptionsData,
    // updateDisbursalReportOptionsData,
    updatePayloadWithPath,
    updateCustomerSupportOptionsData,
    filterChipsAdd,
    filterChipsClearAll,
    filterChipsDelete,
    pageChange,
    rowsPerPageChange,
    setDistinctFilters,
    setSearchText,
    resetPagination,
    setDefaultDate,
} = filterSlice.actions;

export default filterSlice.reducer;
