import { createSlice } from '@reduxjs/toolkit';

import {
    downloadCreditReport,
    getCreditReportDetails,
} from '../../cr-details/redux/thunks';

// import { getExistingProductDetails } from './thunks';

const initialState = {
    value: 0,
    customerID: '',
    custId: '',
    prospectDetails: {},
    overViewDetails: {},
    existingProductDetails: {},
    creditReport: {},
    finalConfirmation: {},
    disbursalDetails: {},
    loanStatus: '',
    creditReportDetails: null,
    downloadStatus: {
        loading: false,
        error: null,
        success: false,
    },
};

export const creditReportViewDetailsCheckerSlice = createSlice({
    name: 'creditReportViewDetailsChecker',
    initialState,
    reducers: {
        setCustomerID(state, action) {
            state.customerID = action.payload;
        },
        setLoanStatus(state, action) {
            state.loanStatus = action.payload;
        },
        resetDownloadStatus(state) {
            state.downloadStatus = {
                loading: false,
                error: null,
                success: false,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCreditReportDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getCreditReportDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.creditReportDetails = action.payload?.data;
                state.custId = action.payload?.data?.cust_id;
            })
            .addCase(getCreditReportDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(downloadCreditReport.pending, (state) => {
                state.downloadStatus.loading = true;
                state.downloadStatus.error = null;
                state.downloadStatus.success = false;
            })
            .addCase(downloadCreditReport.fulfilled, (state) => {
                state.downloadStatus.loading = false;
                state.downloadStatus.success = true;
            })
            .addCase(downloadCreditReport.rejected, (state, action) => {
                state.downloadStatus.loading = false;
                state.downloadStatus.error = action.payload;
                state.downloadStatus.success = false;
            })
            .addCase('creditReportViewDetails/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

// Action creators are generated for each case reducer function
export const { setCustomerID, setLoanStatus, resetDownloadStatus } =
    creditReportViewDetailsCheckerSlice.actions;

export default creditReportViewDetailsCheckerSlice.reducer;
