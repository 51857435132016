/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../../../service/apiService';

export const getCustomerDashboardList = createAsyncThunk(
    'CUSTOMER_DASHBOARD',
    async (payload, { rejectWithValue }) => {
        try {
            if (
                payload?.filterRequestPayloadData?.date?.startDate === '' &&
                payload?.filterRequestPayloadData?.date?.endDate === ''
            ) {
                payload.filterRequestPayloadData.date = {};
            }
            const response = await apiService.post(
                '/data/v1/dashboard/customerList',
                {
                    payload: {
                        data: payload.filterRequestPayloadData,
                    },
                },
                { cache: false }
            );

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
